import styled from 'styled-components'

export const FooterContainer = styled.footer`
  background-color: #4e4d4a;
  color: #fff;
  text-align: center;
  padding: 20px;
`

export const FooterContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FooterText = styled.p`
  font-family: 'Fredoka One', cursive;
  font-size: 1.3rem;
  margin: 10px 40px 10px 0;
`

export const Logo = styled.img`
  width: 60px;
  cursor: pointer;
`