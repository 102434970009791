import { Html5 } from '@styled-icons/simple-icons/Html5'
import { Css3 } from '@styled-icons/simple-icons/Css3'
import { Sass } from '@styled-icons/fa-brands/Sass'
import { Javascript } from '@styled-icons/simple-icons/Javascript'
import { Typescript } from '@styled-icons/simple-icons/Typescript'
import { ReactLogo } from '@styled-icons/simple-icons/ReactLogo'
import { Redux } from '@styled-icons/simple-icons/Redux'
import { NodeDotJs } from '@styled-icons/simple-icons/NodeDotJs'
import { Mongodb } from '@styled-icons/simple-icons/Mongodb'
import { Firebase } from '@styled-icons/simple-icons/Firebase'
import { Figma } from '@styled-icons/simple-icons/Figma'
import { Git } from '@styled-icons/simple-icons/Git'


export const data = [
  {
    "icon": Html5,
    "color": "#E64C20",
  },
  {
    "icon": Css3,
    "color": "#254CDD",
  },
  {
    "icon": Sass,
    "color": "#C66495",
  },
  {
    "icon": Javascript,
    "color": "#EFD81A",
  },
  {
    "icon": Typescript,
    "color": "#3073BF",
  },
  {
    "icon": ReactLogo,
    "color": "#5ED3F2",
  },
  {
    "icon": Redux,
    "color": "#7248B6",
  },
  {
    "icon": NodeDotJs,
    "color": "#519942",
  },
  {
    "icon": Mongodb,
    "color": "#11AA50",
  },
  {
    "icon": Firebase,
    "color": "#FFCC2E",
  },
  {
    "icon": Figma,
    "color": "#9D57F6",
  },
  {
    "icon": Git,
    "color": "#E94E31",
  },
]